// === NPM
import React, { useState } from "react";
import { Card, Stack, Step, StepButton, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Edit } from "@/assets/icons/actions/edit.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Comment } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Paper } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import { ReactComponent as Check } from "@/assets/icons/shared/valid.svg";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { colors } from "@/resources/CssConstant";
import { getEnumKeyByValue } from "@/resources/utils";
import { BillingStatus, IStatusChange } from "../../interface";

interface StatusChangesDialogProps {
    vaccinationSiteId: number;
    statusChanges: IStatusChange[];
    onClose: () => void;
}

export default function StatusChangesDialog({
    vaccinationSiteId,
    statusChanges,
    onClose,
}: Readonly<StatusChangesDialogProps>) {
    const [activeStep, setActiveStep] = useState<number>(0);

    const activeIconStyle = {
        color: colors.white,
        backgroundColor: colors.primaryColor,
        borderRadius: 50,
        padding: 5,
    };

    const inactiveIconStyle = {
        color: colors.white,
        backgroundColor: colors.disabled,
        borderRadius: 50,
        padding: 5,
    };

    const renderIcon = (status: string, style) => {
        switch (status) {
            case getEnumKeyByValue(BillingStatus, BillingStatus.PAYMENT_REQUESTED):
            case getEnumKeyByValue(BillingStatus, BillingStatus.INSTRUCTION_ONGOING):
                return <Edit style={style} />;
            case getEnumKeyByValue(BillingStatus, BillingStatus.VALIDATED):
                return <Check style={style} />;
            case getEnumKeyByValue(BillingStatus, BillingStatus.OUTSIDE_CALYPSO):
            case getEnumKeyByValue(BillingStatus, BillingStatus.TO_PAY):
            case getEnumKeyByValue(BillingStatus, BillingStatus.PAID):
                return <Paper style={style} />;
            default:
                return <Edit style={style} />;
        }
    };

    const renderEmptyContent = () => (
        <Card>
            <StyledCardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="bold" color="secondary">
                    Aucun changement de statut à signaler
                </Typography>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
                    {statusChanges.map((statusChange, index) => (
                        <Step key={index}>
                            <StepButton onClick={() => setActiveStep(index)}>
                                <StepLabel
                                    StepIconComponent={() =>
                                        renderIcon(
                                            statusChange.status,
                                            activeStep === index ? activeIconStyle : inactiveIconStyle
                                        )
                                    }
                                >
                                    <Typography variant="bold">{`Changement de statut : ${BillingStatus[statusChange.status]}`}</Typography>
                                </StepLabel>
                            </StepButton>
                            <StepContent>
                                <Stack spacing={1}>
                                    <GenericIconText
                                        text={DateTime.fromISO(statusChange.date).toLocaleString()}
                                        icon={<Calendar />}
                                        iconTooltip="Date du changement"
                                    />
                                    <GenericIconText
                                        text={`${statusChange.authorId} - ${statusChange.authorLastname} ${statusChange.authorFirstname}`}
                                        icon={<User />}
                                        iconTooltip="Auteur"
                                    />
                                    {statusChange.comment && (
                                        <GenericIconText
                                            text={statusChange.comment}
                                            icon={<Comment />}
                                            iconTooltip="Motif de rejet"
                                        />
                                    )}
                                </Stack>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            title={`Historique des modifications du statut du chantier n°${vaccinationSiteId}`}
            onClose={onClose}
            renderContent={() => (statusChanges.length > 0 ? renderContent() : renderEmptyContent())}
            renderActions={() => (
                <SecondaryButton onClick={onClose} variant="outlined">
                    Fermer
                </SecondaryButton>
            )}
        />
    );
}
