// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { Add } from "@mui/icons-material";
import { Collapse, Skeleton, Stack } from "@mui/material";
import { AxiosResponse } from "axios";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { HttpStatus } from "@/interfaces/global";
import BillingService from "@/services/BillingService";
import { IVatCreateUpdate, IVatVersion } from "../../../../interface";
import VatVersionForm from "./containers/VatVersionForm";
import VatVersionTile from "./containers/VatVersionTile";

interface VatVersionsDialog {
    vatKey: string;
    title: string;
    onClose: () => void;
}

export default function VatVersionsDialog({ vatKey, title, onClose }: Readonly<VatVersionsDialog>) {
    const [versions, setVersions] = useState<IVatVersion[]>([]);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getVatVersions();
    }, []);

    const getVatVersions = async () => {
        setLoading(true);
        const res = await BillingService.getVatVersions(vatKey);
        if (res.status === HttpStatus.OK) {
            setVersions(res.data);
        }
        setLoading(false);
    };

    const handleSuccess = (res: AxiosResponse, status: HttpStatus, message: string) => {
        if (res.status === status) {
            toast.success(message);
            getVatVersions();
        }
    };

    const createVersion = async (data: IVatCreateUpdate) => {
        const res = await BillingService.createVatVersion(vatKey, data);
        handleSuccess(res, HttpStatus.CREATED, "Vous avez ajouté une nouvelle version");
        setIsAdding(false);
    };

    const updateVersion = async (data: IVatCreateUpdate, uuid: string) => {
        const res = await BillingService.updateVatVersion(uuid, data);
        handleSuccess(res, HttpStatus.NO_CONTENT, "Vos modifications ont bien été enregistrées");
    };

    const deleteVersion = async (uuid: string) => {
        const res = await BillingService.deleteVatVersion(uuid);
        handleSuccess(res, HttpStatus.NO_CONTENT, "La version a bien été supprimée");
    };

    const renderContent = () =>
        loading ? (
            <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
        ) : (
            <Stack alignItems="center" spacing={2}>
                <GenericButton
                    onClick={() => setIsAdding(true)}
                    label="Ajouter une version"
                    startIcon={<Add />}
                    disabled={isAdding}
                />
                <TransitionGroup style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
                    {isAdding && (
                        <Collapse>
                            <VatVersionForm
                                vatVersion={{
                                    uuid: null,
                                    version: versions.length + 1,
                                    startDate: null,
                                    rate: null,
                                    used: false,
                                }}
                                constraints={{
                                    previousDate: versions[0].startDate,
                                    previousRate: versions[0].rate,
                                }}
                                onCancel={() => setIsAdding(false)}
                                onSubmit={createVersion}
                            />
                        </Collapse>
                    )}
                    {versions.map((v, index) => (
                        <Collapse key={v.uuid}>
                            <VatVersionTile
                                vatVersion={v}
                                constraints={{
                                    previousDate: versions[index + 1]?.startDate,
                                    nextDate: versions[index - 1]?.startDate,
                                    previousRate: versions[index + 1]?.rate,
                                    nextRate: versions[index - 1]?.rate,
                                }}
                                updateVersion={updateVersion}
                                deleteVersion={deleteVersion}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </Stack>
        );

    return (
        <GenericDialog
            title={`Gestion des versions de ${title}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
