// === NPM
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Card, CardContent, FormControl, Grid, Stack, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Car } from "@/assets/icons/shared/car.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import {
    BillingStatus,
    BillingStatusKeys,
    IBillable,
    IBillingStatusDtoOut,
} from "@/components/VaccinationIahp/Billing/interface";
import WorkshopsAccordion from "@/components/VaccinationIahp/containers/WorkshopsAccordion";
import { VaccinationIahpContext } from "@/components/VaccinationIahp/interface";
import { HttpStatus } from "@/interfaces/global";
import { IVaccinationSite, VaccinationSiteUserType } from "@/interfaces/vaccination";
import { FORM_TEXT } from "@/resources/FormUtils";
import { getEnumKeyByValue, toLocaleDateFormat, typedObjectKeys } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";

interface VaccinationSiteBillingStatusDialogProps {
    view?: boolean;
    vaccinationSite: IVaccinationSite;
    billable: IBillable;
    editBillingStatus?: (id: number, data: IBillingStatusDtoOut) => void;
    onClose: () => void;
}

export default function VaccinationSiteBillingStatusDialog({
    view = false,
    vaccinationSite,
    billable,
    editBillingStatus,
    onClose,
}: Readonly<VaccinationSiteBillingStatusDialogProps>) {
    const { horsePower } = useOutletContext<VaccinationIahpContext>();
    const [billingStatusEnumKeys, ...others] = typedObjectKeys(BillingStatus);
    const [allowedNextStatuses, setAllowedNextStatuses] = useState<BillingStatusKeys[]>([
        BillingStatusKeys[billable.billingStatus],
    ]);

    const newStatusDtoSchema = z
        .object({
            status: z.enum([billingStatusEnumKeys, ...others]),
            reason: z
                .string()
                .trim()
                .max(2000, { message: "Ce champ ne peut pas contenir plus de 2000 caractères" })
                .nullable()
                .optional(),
        })
        .superRefine((data, ctx) => {
            if (data.status === getEnumKeyByValue(BillingStatus, BillingStatus.REJECTED) && !data.reason) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["reason"],
                });
            }
        });

    type FormSchema = z.infer<typeof newStatusDtoSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<FormSchema>({
        resolver: zodResolver(newStatusDtoSchema),
        defaultValues: {
            status: billable.billingStatus,
            reason: null,
        },
    });

    const status = watch("status");

    useEffect(() => {
        if (!view) {
            getAllowedNextStatuses();
        }
    }, []);

    const getAllowedNextStatuses = async () => {
        let withCurrentStatus: BillingStatusKeys[] = [];
        const resStatuses = await VaccinationService.getVaccinationSiteAllowedNextStatuses(billable.id);
        if (resStatuses.status === HttpStatus.OK) {
            withCurrentStatus = resStatuses.data;
        }
        withCurrentStatus.push(BillingStatusKeys[billable.billingStatus]);
        setAllowedNextStatuses(withCurrentStatus);
    };

    const renderBillableVaccinationSiteInformation = () => {
        return (
            <GenericAccordion title="Informations du chantier" defaultExpanded>
                <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={12} md={4} display="flex" gap={1}>
                        <Box>
                            <Calendar />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Informations du chantier
                                </Typography>
                            </Box>
                            <GenericTitleValueText
                                title="Date de l'intervention"
                                value={toLocaleDateFormat(vaccinationSite.date)}
                            />
                            <GenericTitleValueText
                                title="Vaccinateur"
                                value={VaccinationSiteUserType[vaccinationSite.userType]}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" gap={1}>
                        <Box>
                            <User />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Responsable de l'intervention
                                </Typography>
                            </Box>
                            <GenericTitleValueText
                                title="Vétérinaire"
                                value={`${vaccinationSite.veterinaryFirstname} ${vaccinationSite.veterinaryLastname}`}
                            />
                            <GenericTitleValueText title="DPE" value={vaccinationSite.dpeName} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" gap={1}>
                        <Box>
                            <Car />
                        </Box>
                        <Stack spacing={1}>
                            <Box mt={-0.25}>
                                <Typography variant="bold" fontSize={18}>
                                    Déplacement
                                </Typography>
                            </Box>
                            <GenericTitleValueText title="Kilomètres parcourus" value={vaccinationSite.distance} />
                            <GenericTitleValueText title="Immatriculation" value={vaccinationSite.plateNumber} />
                            <GenericTitleValueText
                                title="Chevaux fiscaux"
                                value={horsePower.find((h) => h.key === vaccinationSite.horsePower)?.label}
                            />
                            <GenericTitleValueText title="Temps passé" value={vaccinationSite.interventionDuration} />
                        </Stack>
                    </Grid>
                </Grid>
            </GenericAccordion>
        );
    };

    return (
        <GenericDialog
            maxWidth="xl"
            title={`${view ? "Récapitulatif" : "Modification du statut"} du chantier n°${vaccinationSite.id}`}
            onClose={onClose}
            renderContent={() => (
                <Stack spacing={2}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="bold" fontSize={18}>
                                    Statut de facturation
                                </Typography>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value || ""}
                                            label={view ? "Statut" : "Nouveau statut"}
                                            onChange={onChange}
                                            required
                                            error={!!error}
                                            helperText={error?.message}
                                            options={allowedNextStatuses.map((status) => ({
                                                value: status,
                                                label: BillingStatus[status],
                                            }))}
                                            disabled={view}
                                        />
                                    )}
                                />
                                {status === getEnumKeyByValue(BillingStatus, BillingStatus.REJECTED) &&
                                    status !== billable.billingStatus && (
                                        <FormControl fullWidth error={!!errors.reason} required>
                                            <Controller
                                                name="reason"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <GenericComment
                                                        value={value || ""}
                                                        label="Motif de rejet"
                                                        onChange={onChange}
                                                        helperText={error?.message}
                                                        rows={3}
                                                        placeholder="Motif de rejet"
                                                        required
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    )}
                            </Stack>
                        </CardContent>
                    </Card>
                    {renderBillableVaccinationSiteInformation()}
                    <WorkshopsAccordion
                        workshops={vaccinationSite.workshops}
                        departmentInseeCode={vaccinationSite.departmentInseeCode}
                        farmId={vaccinationSite.farmId}
                        farmName={vaccinationSite.farmName}
                    />
                </Stack>
            )}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit((data: FormSchema) => {
                        editBillingStatus(billable.id, { status: data.status, reason: data.reason || null });
                    })}
                    closeLabel={`${view ? "Fermer" : "Annuler"}`}
                    validLabel="Enregistrer"
                    displaySubmit={!view}
                />
            )}
        />
    );
}
