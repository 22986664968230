// === NPM
import React from "react";
import { Box, ButtonBase, Paper, Stack, Typography } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface PresetFilterProps {
    icon: JSX.Element;
    title: string;
    value: number;
    onClick: () => void;
    selected?: boolean;
    isOneSelected: boolean;
}

const styles = {
    original: { width: 1, height: 1, borderRadius: 5 },
    selected: {
        width: 1,
        height: 1,
        borderRadius: 5,
        border: "3px solid " + colors.primaryColor,
        boxShadow: "5px 5px 2px 1px" + colors.primaryColorOpacity80,
    },
};

export default function PresetFilter({
    icon,
    title,
    value,
    onClick,
    selected = false,
    isOneSelected,
}: Readonly<PresetFilterProps>) {
    return (
        <ButtonBase onClick={onClick} sx={selected ? styles.selected : styles.original}>
            <Paper
                sx={{
                    width: 1,
                    height: 1,
                    padding: 3,
                    borderRadius: 4.5,
                    display: "flex",
                    alignItems: "flex-end",
                }}
                variant={selected ? "elevation" : isOneSelected ? "outlined" : "elevation"}
                elevation={isOneSelected ? 0 : 5}
            >
                <Stack direction="row" spacing={2} alignItems="flex-end">
                    <Box>{icon}</Box>
                    <Stack alignItems="flex-start" color={colors.primaryColor}>
                        <Typography variant={selected ? "extraBold" : "bold"} fontSize={25}>
                            {value}
                        </Typography>
                        <Typography variant={selected ? "extraBold" : "bold"} fontSize={15} align="left">
                            {title.toLocaleUpperCase()}
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </ButtonBase>
    );
}
